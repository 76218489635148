import React from "react"

const IndexPage = () => {
    if (typeof window !== `undefined`) {
        if (localStorage.getItem('lastLesson')) {
            window.location.replace(`/${localStorage.getItem('lastLesson')}`)
        } else {
            window.location.replace(`/exodus`)
        }
    }

    return <></>
}

export default IndexPage
